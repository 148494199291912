.fadeMe {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.spinner {
  margin: auto;
  margin-top: 200px;
  z-index: 999;
}

.novel-pages-list {
  /* overflow: scroll; */
  /* scrollbar-color: red orange;
  scrollbar-width: thin; */
  height: 200px;
}

.novel-audio-controler {
  min-width: 300px;
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.novel-audio-item {
  width: 30px;
  height: 30px;
  color: #31c27c;
}

.novel-info {
  display: flex;
  margin: 10px;
  border: dotted #31c27c;
  /* border-width: 10px 4px; */
  padding: 10px;
  /* border: dashed #31c27c; */
  border-width: 2px 4px;
  /* border-radius: 10% 30% 50% 70%; */
}

.coverImageInfo {
  width: 150px;
  height: 200px;
}

@media only screen and (max-device-width: 600px) {
  .song_table {
    font-size: 20px;
  }

  .coverImageInfo {
    width: 100%;
    height: auto;
  }
  .novel-info {
    display: block;
  }
}
